<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">

        <CRow>
          <CCol sm="12">
            <CCard>
              <CCardHeader>
                <CCardTitle>Create a new Invoice</CCardTitle>
              </CCardHeader>

              <CCardBody>
                <CRow>
                  <CCol sm="6">
                    <CSelect
                      placeholder="Select"
                      label="Location"
                      :options="locations"
                      :value.sync="invoice.location_id"
                    />
                  </CCol>
                  <CCol sm="6">
                    <CSelect v-if="$store.getters.isAdmin"
                      placeholder="Select"
                      label="State"
                      :options="[{'label': 'Unpaid', 'value': 'unpaid'}, {'label': 'Paid', 'value': 'paid'}, {'label': 'Reminded', 'value': 'reminded'}]"

                      :value.sync="invoice.state"
                    />
                    <CInput v-if="!$store.getters.isAdmin"
                        type="hidden"
                        v-model="invoice.location_id"
                    />
                  </CCol>
                </CRow>
              </CCardBody>

            </CCard>

            <CCard>

              <CCardHeader>
                <CCardSubtitle>Rows</CCardSubtitle>
              </CCardHeader>

              <CCardBody>

                  <CRow>
                    <CCol sm="12" class="add-btn">
                      <CButton
                        variant="outline"
                        type="submit"
                        color="warning"
                        @click="addInvoiceRow()"
                      >
                        <CIcon name="cil-playlist-add" />Add Row
                      </CButton>
                    </CCol>

                    <br />

                    <CContainer
                      v-for="(invoice_row, index) in invoice.invoice_rows"
                      id="invoiceRowsContainer"
                    >
                      <CRow>
                        <CCol sm="1">
                          <CButton variant="outline" type="submit" size="sm" color="danger" @click="deleteInvoiceRow(index)">
                            <CIcon class='close-btn' name="cil-trash"/>
                          </CButton>
                        </CCol>
                        <CCol sm="4">
                          <CInput
                            name="description"
                            label="Description"
                            type="text"
                            placeholder="Description"
                            v-model="invoice_row.description"
                          />
                        </CCol>
                        <CCol sm="3">
                          <CInput
                            name="description"
                            label="Tax"
                            type="number"
                            prepend="%"
                            v-model="invoice_row.tax_percentage"
                            min=0
                          />
                        </CCol>
                        <CCol sm="3">
                          <CInput
                            name="price_inc"
                            label="Price Inc"
                            type="number"
                            prepend="€"
                            v-model="invoice_row.price_inc"
                            min=0
                          />
                        </CCol>
                      </CRow>
                    </CContainer>
                  </CRow>
              </CCardBody>
            </CCard>

            <CCard>

              <CCardHeader>
                <CCardSubtitle>Summary</CCardSubtitle>
              </CCardHeader>

              <CCardBody>
                <CRow>
                  <CContainer>
                    <CRow>
                      <CCol sm="2">
                        <p>Price ex.</p>
                        <strong>€{{ invoice.total_price_ex }}</strong>
                      </CCol>
                      <CCol sm="2">
                        <p>Tax</p>
                        <strong>€{{ invoice.total_price_tax }}</strong>
                      </CCol>
                      <CCol sm="2">
                        <p>Price inc.</p>
                        <strong>€{{ invoice.total_price_inc }}</strong>
                      </CCol>
                    </CRow>
                  </CContainer>
                </CRow>

              </CCardBody>
            </CCard>
          </CCol>

          <CCol>
            <CButton @click="createInvoice" variant="outline" type="submit" color="primary">
              <CIcon name="cil-check-circle"/> Create
            </CButton>
          </CCol>

        </CRow>

      </transition>
    </CCol>
  </CRow>
</template>

<script>

import InvoiceAPI from '/app/src/api/invoice.js'
import LocationAPI from '/app/src/api/location.js'

export default {
  name: 'Create',
  data: () => {
    return {
      locationAPI: new LocationAPI(),
      invoiceAPI: new InvoiceAPI(),
      locations: [],
      invoice: {
        state: 'unpaid',
        invoice_rows: []
      },
      customers: []
    }
  },
  created: function() {
    console.log('created')
    this.getLocations()
    this.addInvoiceRow()
  },
  methods: {
    getLocations: function() {
      this.$store.dispatch('loading')
      let self = this
      this.locationAPI.all()
      .then((locations) => {
        console.log(locations)
        self.$store.dispatch('stopLoading');
        for (let i = 0; i < locations.length; i++) {
          self.locations.push({'label': locations[i].name, 'value': locations[i].id})
        }
      })
      .catch((error) => {
        this.$store.dispatch('stopLoading');
        this.$alert.show({type: 'danger', 'message': error})
      })
    },

    createInvoice: function() {
      this.$store.dispatch("loading")

      this.invoiceAPI
        .create(this.invoice)
        .then((result) => {
          this.$store.dispatch("stopLoading");
          this.$router.push({path: `/manager/invoices/${result.id}` })
        })
        .catch(error => {
          this.$store.dispatch("stopLoading");
          this.$alert.show({type: 'danger', 'message': error})
        });
    },

    addInvoiceRow: function() {
      this.invoice.invoice_rows.push({
        description: '',
        tax_percentage: 21,
        price_inc: 0
      })
    },
    
    deleteInvoiceRow(index) {
      this.invoice.invoice_rows.splice(index, 1);
    }

  },

  watch: {
    'invoice.invoice_rows': {
      // This will let Vue know to look inside the array
      deep: true,
      // We have to move our method to a handler field
      handler(val) {

        // Reset values
        this.invoice.total_price_ex = 0;
        this.invoice.total_price_tax = 0;
        this.invoice.total_price_inc = 0;

        // Calculate new prices
        for (let i = 0; i < this.invoice.invoice_rows.length; i++) {
            let row = this.invoice.invoice_rows[i]            
            this.invoice.total_price_tax += parseFloat( (row.price_inc / (100 + parseFloat(row.tax_percentage)) * parseFloat(row.tax_percentage)).toFixed(2) )
            this.invoice.total_price_inc += parseFloat( parseFloat(row.price_inc).toFixed(2) )
            this.invoice.total_price_ex = parseFloat( (parseFloat(this.invoice.total_price_inc) - parseFloat(this.invoice.total_price_tax)).toFixed(2) )
        }
      }
    }
  }

}
</script>

<style scoped>

.add-btn {
  margin-bottom: 20px !important;
}

.summary {
  margin-bottom: 20px;
}

.close-btn {
  margin: 0;
}

#invoiceRowsContainer .btn-outline-danger {
  margin-top: 35px;
}

</style>